.collection-controls {
  padding: 0;
  margin: 1rem 1rem 0.5rem 1rem;
}

.collection-controls h1 {
  font-size: 3vh;
}

.collection-controls button {
  width: 100%;
  margin-top: 1rem;
}

form {
  width: 100%;
}

.collection-form {
  margin-top: 8px;
}

.collection-form > button,
.react-datepicker-wrapper {
  width: 100%;
}

.Table {
  width: 80%;
  margin-left: 10px;
}
.headerRow,
.evenRow {
  border-bottom: 1px solid #e0e0e0;
}
.evenRow:hover {
  background-color: whitesmoke;
}
.headerColumn {
  text-transform: none;
}
.exampleColumn {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.noRows {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  color: #bdbdbd;
}
